import React, { useState, useEffect } from 'react';
import effects from './signUp.css';

const SignUpForm = ({ onClose }) => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        mobile: ''
    });

    const [usernameAvailable, setUsernameAvailable] = useState(true);
    const [usernameError, setUsernameError] = useState('');

    useEffect(() => {
        const checkUsername = async (username) => {
            if (username.trim()) {
                try {
                    //const response = await fetch(http:/localhost:3500/users/${username.trim()});
                    const data = await response.json();
                    setUsernameAvailable(data.available);
                    if (!data.available) {
                        // setUsernameError('Username is taken. Try another.');
                    } else {
                        setUsernameError('');
                    }
                } catch (error) {
                    console.error('Error checking username:', error);
                    // setUsernameError('Error checking username. Please try again later.');
                }
            } else {
                setUsernameAvailable(true);
                setUsernameError('');
            }
        };

        checkUsername(formData.username); // Initial check when component mounts

    }, [formData.username]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
        setUsernameAvailable(true); // Reset availability status on any change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!usernameAvailable) {
            console.error('Cannot submit form: username is taken');
            setUsernameError('Username is taken. Please choose another.');
            return;
        }

        console.log('Form Data:', formData); // Log the form data to debug

        try {
            const response = await fetch('https://api.stemverse.app/registerUsers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log('User registered successfully');
                onClose(); // Close the form
            } else {
                console.error('Failed to register user');
                setUsernameError('Failed to register user. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setUsernameError('Error registering user. Please try again later.');
        }
    };

    return (
        <div className={effects.signUpForm}>
            <h2>User Register</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <div>
                        <label htmlFor="username">Create a username</label>
                    </div>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Username"
                        size="40"
                        required
                        value={formData.username}
                        onChange={handleChange}
                    />
                    {usernameError && <p style={{ color: 'red' }}>{usernameError}</p>}
                </div>
                <div>
                    <div>
                        <label htmlFor="password">Create a password</label>
                    </div>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        size="40"
                        required
                        value={formData.password}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>
                        <label htmlFor="email">Enter Email</label>
                    </div>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        size="40"
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <div>
                        <label htmlFor="mobile">Enter Mobile Number</label>
                    </div>
                    <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        size="40"
                        placeholder="Mobile"
                        required
                        value={formData.mobile}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit" disabled={!usernameAvailable}>Sign Up</button>
                <button type="button" onClick={onClose}>Close</button>
            </form>
        </div>
    );
};

export default SignUpForm;