import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";

const SimulationComponent = ({ vm }) => {
    const [activeSimulation, setActiveSimulation] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadedExtensions, setLoadedExtensions] = useState([]);

    const carConfig = {
        loaderUrl: "/Build/car_sim.loader.js",
        dataUrl: "/Build/car_sim.data/webgl.data",
        frameworkUrl: "/Build/car_sim.framework.js/build.framework.js",
        codeUrl: "/Build/car_sim.wasm/build.wasm",
    };
    const armConfig = {
        loaderUrl: "/Build/arm.loader.js",
        dataUrl: "/Build/arm.data/webgl.data",
        frameworkUrl: "/Build/arm.framework.js/build.framework.js",
        codeUrl: "/Build/arm.wasm/build.wasm",
    };
    const spiderConfig = {
        loaderUrl: "Build/spider_sim.loader.js",
        dataUrl: "Build/spider_sim.data/webgl.data",
        frameworkUrl: "Build/spider_sim.framework.js/build.framework.js",
        codeUrl: "Build/spider_sim.wasm/build.wasm"
    };

    const { unityProvider: carUnityProvider, isLoaded: isCarLoaded, loadingProgression: carLoadingProgression, sendMessage: sendCarMessage } = useUnityContext(carConfig);
    const { unityProvider: armUnityProvider, isLoaded: isArmLoaded, loadingProgression: armLoadingProgression, sendMessage: sendArmMessage } = useUnityContext(armConfig);
    const { unityProvider: spiderUnityProvider, isLoaded: isSpiderLoaded, loadingProgression: spiderLoadingProgression, sendMessage: sendSpiderMessage } = useUnityContext(spiderConfig);

    const jointDataRef = useRef({
        ArmAngle: "0",
        ShoulderAngle: "0",
        ElbowAngle: "0",
        Wrist1Angle: "0",
        Wrist2Angle: "0",
        Grip1Angle: "0",
        Speed: "6"
    });

    useEffect(() => {
        const checkLoadedExtensions = () => {
            if (!vm || !vm.extensionManager || typeof vm.extensionManager.isExtensionLoaded !== 'function') {
                setErrorMessage('VM or extension manager not properly initialized.');
                setLoadedExtensions([]);
                return;
            }

            try {
                const extensions = [];
                if (vm.extensionManager.isExtensionLoaded('car')) extensions.push('car');
                if (vm.extensionManager.isExtensionLoaded('arm')) extensions.push('arm');
                if (vm.extensionManager.isExtensionLoaded('spider')) extensions.push('spider');
                setLoadedExtensions(extensions);
                setErrorMessage('');

                if (extensions.length === 1 && !activeSimulation) {
                    setActiveSimulation(extensions[0]);
                } else if (extensions.length > 1 && !activeSimulation) {
                    setActiveSimulation('car');
                } else if (extensions.length === 0) {
                    setErrorMessage('No supported simulation extension is loaded.');
                    setActiveSimulation(null);
                }
            } catch (error) {
                console.error('Error checking loaded extensions:', error);
                setErrorMessage('Error checking loaded extensions. Please try again.');
                setLoadedExtensions([]);
                setActiveSimulation(null);
            }
        };

        checkLoadedExtensions();
        const intervalId = setInterval(checkLoadedExtensions, 1000);
        return () => clearInterval(intervalId);
    }, [vm, activeSimulation]);

    useEffect(() => {
        if (activeSimulation === 'car' && isCarLoaded) {
            setupCarControls();
        } else if (activeSimulation === 'arm' && isArmLoaded) {
            setupArmControls();
        } else if (activeSimulation === 'spider' && isSpiderLoaded) {
            setupSpiderControls();
        }
    }, [activeSimulation, isCarLoaded, isArmLoaded, isSpiderLoaded]);

    const setupCarControls = useCallback(() => {
        let isForward = false;
        let isBackward = false;
        let isLeft = false;
        let isRight = false;

        const sendInput = () => {
            if (isForward) sendCarMessage('Car', 'ReceiveInput', 'forward');
            if (isBackward) sendCarMessage('Car', 'ReceiveInput', 'backward');
            if (isLeft) sendCarMessage('Car', 'ReceiveInput', 'left');
            if (isRight) sendCarMessage('Car', 'ReceiveInput', 'right');
        };

        const inputInterval = setInterval(sendInput, 100);

        window.moveForward = () => { isForward = true; setErrorMessage(''); };
        window.stopForward = () => { isForward = false; };
        window.moveBackward = () => { isBackward = true; setErrorMessage(''); };
        window.stopBackward = () => { isBackward = false; };
        window.turnLeft = () => { isLeft = true; setErrorMessage(''); };
        window.stopLeft = () => { isLeft = false; };
        window.turnRight = () => { isRight = true; setErrorMessage(''); };
        window.stopRight = () => { isRight = false; };

        return () => clearInterval(inputInterval);
    }, [sendCarMessage]);

    const setupArmControls = useCallback(() => {
        const mapAngle = (angle, isGrip) => {
            if (isGrip) {
                // For grip, map 110-150 to -40-0
                return Math.round((angle - 110) * (40 / 40) - 40).toString();
            } else {
                // For other joints, map 0-180 to -90-90
                return (angle - 90).toString();
            }
        };

        window.moveArmJoint = (jointName, angle) => {
            const jointData = { ...jointDataRef.current };
            const isGrip = jointName.toLowerCase() === "grip1";

            switch (jointName.toLowerCase()) {
                case "arm": jointData.ArmAngle = mapAngle(angle, false); break;
                case "shoulder": jointData.ShoulderAngle = mapAngle(angle, false); break;
                case "elbow": jointData.ElbowAngle = mapAngle(angle, false); break;
                case "wrist1": jointData.Wrist1Angle = mapAngle(angle, false); break;
                case "wrist2": jointData.Wrist2Angle = mapAngle(angle, false); break;
                case "grip1": jointData.Grip1Angle = mapAngle(angle, true); break;
                default: return;
            }

            jointDataRef.current = jointData;
            sendArmMessage('base', 'SetTargetAngles', JSON.stringify(jointData));
        };
    }, [sendArmMessage]);
    const setupSpiderControls = useCallback(() => {
        // Add any specific controls for the spider simulation if needed
        // This is where you would set up any custom controls or interactions for the spider game
    }, [sendSpiderMessage]);

    const handleSimulationChange = useCallback((simulationType) => {
        if (loadedExtensions.includes(simulationType)) {
            setActiveSimulation(simulationType);
            setErrorMessage('');
        } else {
            setErrorMessage(`${simulationType.charAt(0).toUpperCase() + simulationType.slice(1)} extension is not loaded.`);
        }
    }, [loadedExtensions]);

    const UnityGameWrapper = useCallback(({ unityProvider, isLoaded, loadingProgression }) => {
        const progress = Math.round(loadingProgression * 100);
    
        return (
            <div className="unity-container relative">
                {!isLoaded && (
                    <p className="loading-text absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg font-semibold">
                        Loading Application... {progress}%
                    </p>
                )}
                <div className="progress-bar absolute bottom-0 left-0 w-full h-2 bg-gray-200">
                    <div className="progress h-full bg-blue-500" style={{ width: `${progress}%` }}></div>
                </div>
                <Unity
                    unityProvider={unityProvider}
                    className={`unity-canvas w-[480px] h-[364px] ${isLoaded ? 'visible' : 'invisible'}`}
                />
            </div>
        );
    }, []);

    const SimulationButton = ({ simulationType }) => (
        <button
            onClick={() => handleSimulationChange(simulationType)}
            disabled={!loadedExtensions.includes(simulationType)}
            className={`
                px-5 py-2.5 mr-2 font-bold text-sm rounded-t-lg transition-colors duration-200
                ${activeSimulation === simulationType
                    ? 'bg-blue-100 text-blue-800 border-2 border-blue-500 border-b-0'
                    : 'bg-gray-100 text-gray-800 border border-gray-300 border-b-0'}
                ${loadedExtensions.includes(simulationType)
                    ? 'cursor-pointer hover:bg-blue-50'
                    : 'cursor-not-allowed opacity-60'}
            `}
        >
            {simulationType.charAt(0).toUpperCase() + simulationType.slice(1)}
        </button>
    );

    return (
        <div className="simulation-component">
            <div id="simulation-selector" className="flex justify-start mb-5">
                <SimulationButton simulationType="car" />
                <SimulationButton simulationType="arm" />
                <SimulationButton simulationType="spider" />
            </div>
            {errorMessage && <div className="text-red-500 mt-2.5">{errorMessage}</div>}
            {activeSimulation === 'car' && (
                <UnityGameWrapper 
                    unityProvider={carUnityProvider} 
                    isLoaded={isCarLoaded} 
                    loadingProgression={carLoadingProgression}
                />
            )}
            {activeSimulation === 'arm' && (
                <UnityGameWrapper 
                    unityProvider={armUnityProvider} 
                    isLoaded={isArmLoaded} 
                    loadingProgression={armLoadingProgression}
                />
            )}
            {activeSimulation === 'spider' && (
                <UnityGameWrapper 
                    unityProvider={spiderUnityProvider} 
                    isLoaded={isSpiderLoaded} 
                    loadingProgression={spiderLoadingProgression}
                />
            )}
        </div>
    );
};

export default SimulationComponent;