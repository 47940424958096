
import React, { useState, useEffect, useRef } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Button } from "../../components/ui/button"
import { Card, CardContent } from "../../components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"
import { Rocket, Stars, Zap, Code, Cpu, Database, Wifi, Cog, Terminal, Bot } from "lucide-react"
import '../../playground/global.css'

const topics = [
  { name: "Coding", icon: "💻", color: "bg-purple-500" },
  { name: "AI and ML", icon: "🧠", color: "bg-blue-500" },
  { name: "IoT/ELEC", icon: "🔌", color: "bg-green-500" },
  { name: "AR/VR", icon: "🥽", color: "bg-indigo-500" },
  { name: "GEN AI", icon: "🤖", color: "bg-emerald-500" },
  { name: "Robotics", icon: "🦾", color: "bg-pink-500" },
  { name: "Game Dev", icon: "🎮", color: "bg-yellow-500" },
  { name: "3D Print", icon: "🖨️", color: "bg-red-500" },
  { name: "Drones", icon: "🚁", color: "bg-cyan-500" },
  { name: "Cyber Kids", icon: "👨‍💻", color: "bg-orange-500" },
]

const symbols = [Code, Cpu, Database, Wifi, Cog, Terminal, Bot]

export default function LandingPage({handleTopicClick} ) {
  const [hoveredTopic, setHoveredTopic] = useState(null)
  const [floatingSymbols, setFloatingSymbols] = useState([])
  const [showWelcome, setShowWelcome] = useState(true)
  const [showQuiz, setShowQuiz] = useState(false)
  const [quizAnswer, setQuizAnswer] = useState("")
  const [isCorrect, setIsCorrect] = useState(null)
  const rocketRef = useRef(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setFloatingSymbols((prev) => [
        ...prev,
        {
          id: Date.now(),
          left: Math.random() * 100,
          duration: Math.random() * 10 + 5,
          size: Math.random() * 30 + 20,
          rotation: Math.random() * 360,
          color: `hsl(${Math.random() * 360}, 70%, 60%)`,
          Symbol: symbols[Math.floor(Math.random() * symbols.length)],
        },
      ].slice(-15))
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  const launchRocket = () => {
    if (rocketRef.current) {
      rocketRef.current.style.transform = "translateY(-100vh) rotate(-45deg)"
      rocketRef.current.style.transition = "transform 2s ease-in-out"
    }
  }

  // const handleTopicClick = (topic) => {
  //   console.log(`Selected topic: ${topic.name}`)
  //   // Add navigation logic here
  // }

  const handleQuizSubmit = (e) => {
    e.preventDefault()
    setIsCorrect(quizAnswer.toLowerCase() === "algorithm")
  }

  return (
    <div className="min-h-screen bg-gradient-to-b  from-blue-900 via-cyan-600 to-blue-900 flex flex-col items-center justify-center p-4 space-y-8 relative overflow-hidden">
      {floatingSymbols.map((symbol) => (
        <motion.div
          key={symbol.id}
          className="absolute z-0"
          style={{
            left: `${symbol.left}%`,
            color: symbol.color,
          }}
          initial={{ top: "100%", rotate: 0 }}
          animate={{ top: "-10%", rotate: symbol.rotation }}
          transition={{ duration: symbol.duration, ease: "linear" }}
        >
          <symbol.Symbol size={symbol.size} />
        </motion.div>
      ))}

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
        className="text-center space-y-4 z-10"
      >
        <h1 className="text-6xl md:text-8xl font-bold text-white drop-shadow-lg flex items-center justify-center">
          <span className="text-yellow-300">STEM</span>
          <motion.div
            ref={rocketRef}
            whileHover={{ scale: 1.2, rotate: 15 }}
            onClick={launchRocket}
            className="mx-4 cursor-pointer"
          >
            <Rocket className="text-red-500" size={64} />
          </motion.div>
          <span className="text-green-300">Verse</span>
        </h1>
        <p className="text-2xl text-white font-semibold">
          Build Awesome Projects with Blocks!
        </p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Card className="w-full max-w-5xl bg-white bg-opacity-90 backdrop-blur-md z-10 rounded-3xl shadow-xl overflow-hidden">
          <CardContent className="p-8">
            <Tabs defaultValue="topics" className="w-full">
              <TabsList className="grid w-full grid-cols-2 mb-6">
                <TabsTrigger value="topics" className="text-lg font-semibold">
                  <Stars className="mr-2" /> Topics
                </TabsTrigger>
                <TabsTrigger value="about" className="text-lg font-semibold">
                  <Zap className="mr-2" /> About STEM World
                </TabsTrigger>
              </TabsList>
              <TabsContent value="topics">
                <motion.div
                  className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ staggerChildren: 0.1 }}
                >
                  {topics.map((topic) => (
                    <motion.div
                      key={topic.name}
                      className={`${topic.color} rounded-2xl p-4 cursor-pointer transition-all transform hover:scale-105 relative`}
                      whileHover={{ scale: 1.05 }}
                      onHoverStart={() => setHoveredTopic(topic.name)}
                      onHoverEnd={() => setHoveredTopic(null)}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      onClick={() => handleTopicClick(topic)}
                      style={{ margin: '0 5px' }}
                    >
                      <div className="flex flex-col items-center justify-center h-full">
                        <span className="text-4xl mb-2">{topic.icon}</span>
                        <span className="text-sm font-bold text-white text-center">{topic.name}</span>
                      </div>
                      <AnimatePresence>
                        {hoveredTopic === topic.name && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 rounded-2xl"
                          >
                            <p className="text-white text-center p-2 text-xs">Click to explore {topic.name}!</p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  ))}
                </motion.div>
              </TabsContent>
              <TabsContent value="about">
                <motion.div
                  className="space-y-4 text-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <h2 className="text-3xl font-bold text-blue-600">Welcome to STEM World!</h2>
                  <p className="text-gray-700">
                    STEM Verse is a super cool place where you can learn to code and create awesome projects using colorful blocks!
                    It's like building with digital LEGO, but your creations come to life on the screen!
                  </p>
                  <p className="text-gray-700">
                    You can make your own games, control robots, explore space, and so much more.
                    Our magical blocks let you bring your wildest ideas to life without having to write complicated code.
                  </p>
                  <p className="text-gray-700">
                    Are you ready to become a STEM superhero? Choose a topic and start your adventure today!
                  </p>
                </motion.div>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="text-center z-10"
      >
        <Button
          className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-4 px-8 rounded-full text-xl shadow-lg transition-all hover:scale-105 hover:rotate-3"
          onClick={() => setShowQuiz(true)}
        >
          Start Your Coding Adventure!
        </Button>
      </motion.div>

      <motion.footer
        className="text-white text-sm z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        © 2024 STEM World. All rights reserved.
      </motion.footer>

      {/* Welcome Modal */}
      <AnimatePresence>
        {showWelcome && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          >
            <Card className="w-full max-w-md bg-white rounded-3xl shadow-2xl overflow-hidden">
              <CardContent className="p-8 text-center">
                <motion.div
                  initial={{ y: -20 }}
                  animate={{ y: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 20 }}
                >
                  <h2 className="text-3xl font-bold text-blue-600 mb-4">Welcome, Young Innovator!</h2>
                  <p className="text-lg text-gray-700 mb-6">
                    Get ready to embark on an exciting journey through the world of STEM!
                    Are you ready to code, create, and conquer?
                  </p>
                  <Button
                    onClick={() => setShowWelcome(false)}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-full text-lg shadow-lg transition-all hover:scale-105"
                  >
                    Let's Go!
                  </Button>
                </motion.div>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Floating Helper */}
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 1, type: "spring", stiffness: 100 }}
        className="fixed bottom-4 right-4 z-40"
      >
        <Button
          className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-4 shadow-lg"
          onClick={() => alert("Need help? Ask a grown-up to contact our support team!")}
        >
          <Bot size={24} />
        </Button>
      </motion.div>

      {/* Quiz Modal */}
      <AnimatePresence>
        {showQuiz && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          >
            <Card className="w-full max-w-md bg-white rounded-3xl shadow-2xl overflow-hidden">
              <CardContent className="p-8 text-center">
                <h2 className="text-3xl font-bold text-blue-600 mb-4">Quick Coding Quiz!</h2>
                <p className="text-lg text-gray-700 mb-6">
                  What do we call a step-by-step procedure for solving a problem or accomplishing a task?
                </p>
                <form onSubmit={handleQuizSubmit} className="space-y-4">
                  <input
                    type="text"
                    value={quizAnswer}
                    onChange={(e) => setQuizAnswer(e.target.value)}
                    className="w-full p-2 border rounded-md text-black"
                    placeholder="Enter your answer"
                  />
                  <Button type="submit" className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full">
                    Submit Answer
                  </Button>
                </form>
                {isCorrect !== null && (
                  <motion.p
                    className={`mt-4 text-lg font-bold ${isCorrect ? 'text-green-500' : 'text-red-500'}`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    {isCorrect ? 'Correct! Great job!' : 'Not quite. Try again!'}
                  </motion.p>
                )}
                <Button
                  onClick={() => setShowQuiz(false)}
                  className="mt-6 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-full"
                >
                  Close
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
  LandingPage.propTypes = {
    handleTopicClick: PropTypes.func.isRequired
  }
}

