import React, { useState, useCallback } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from './game.css';
import './game.css';

const CarGame = () => {
  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } = useUnityContext({
    loaderUrl: "/Build/Pictures.loader.js",
    dataUrl: "/Build/Pictures.data/webgl.data",
    frameworkUrl: "/Build/Pictures.framework.js/build.framework.js",
    codeUrl: "/Build/Pictures.wasm/build.wasm",
  });  

  return (
    <UnityGameWrapper
      unityProvider={unityProvider}
      isLoaded={isLoaded}
      loadingProgression={loadingProgression}
      requestFullscreen={requestFullscreen}
    />
  );
};

const ArmGame = () => {
  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } = useUnityContext({
    loaderUrl: "/Build/roboarm_game.loader.js",
    dataUrl: "/Build/roboarm_game.data/webgl.data",
    frameworkUrl: "/Build/roboarm_game.framework.js/build.framework.js",
    codeUrl: "/Build/roboarm_game.wasm/build.wasm",
  });

  return (
    <UnityGameWrapper
      unityProvider={unityProvider}
      isLoaded={isLoaded}
      loadingProgression={loadingProgression}
      requestFullscreen={requestFullscreen}
    />
  );
};

const SpiderRobotGame = () => {
  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } = useUnityContext({
    loaderUrl: "/Build/robotic_spider.loader.js",
    dataUrl: "/Build/robotic_spider.data/webgl.data",
    frameworkUrl: "/Build/robotic_spider.framework.js/build.framework.js",
    codeUrl: "/Build/robotic_spider.wasm/build.wasm",
  });

  return (
    <UnityGameWrapper
      unityProvider={unityProvider}
      isLoaded={isLoaded}
      loadingProgression={loadingProgression}
      requestFullscreen={requestFullscreen}
    />
  );
};

const UnityGameWrapper = ({ unityProvider, isLoaded, loadingProgression, requestFullscreen }) => {
  const progress = Math.round(loadingProgression * 100);
  const [componentProgress, setComponentProgress] = useState(0);

  // Function to be called from Unity to update progress
  window.handleProgressUpdate = (newProgress) => {
    setComponentProgress(newProgress);
  };

  return (
    <div className={styles.unityContainer}>
      <div className={styles.progressContainer}>
        {!isLoaded && (
          <div>
            {/* <p className={styles.progressText}>Loading Game... {progress}%</p> */}
            <div className={styles.progressBarContainer}>
              <div
                className={styles.progressBarFill}
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        )}

        {isLoaded && (
          <div>
            <div className={styles.progressBarContainer}>
              <div
                className={styles.progressBarFill}
                style={{ width: `${componentProgress}%` }}
              />
            </div>
          </div>
        )}
      </div>

      <div className={styles.unityCanvasWrapper}>
        <Unity
          unityProvider={unityProvider}
          style={{ visibility: isLoaded ? "visible" : "hidden" }}
          className={styles.unityCanvas}
        />
      </div>
    </div>
  );
};

const UnityGame = () => {
  const [gameType, setGameType] = useState('car');

  const handleGameSwitch = useCallback((event) => {
    setGameType(event.target.value);
  }, []);

  return (
    <div className={styles.unityTab}>
      <div className={styles.dropdownContainer}>
        <label htmlFor="gameSelect" className={styles.dropdownLabel}>Select Robo:</label>
        <select
          id="gameSelect"
          value={gameType}
          onChange={handleGameSwitch}
          className={styles.dropdown}
        >
          <option value="car">Build Car-Bot</option>
          <option value="arm">Build Robo-Arm</option>
          <option value="spider">Build Robo-Spider</option>
        </select>
      </div>

      {/* Render the selected game */}
      {gameType === 'car' && <CarGame />}
      {gameType === 'arm' && <ArmGame />}
      {gameType === 'spider' && <SpiderRobotGame />}
    </div>
  );
};

export default UnityGame;
