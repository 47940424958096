import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import MenuBarMenu from './menu-bar-menu.jsx';

// Keep existing message definitions
const LoginDropdownMessages = defineMessages({
    username: {
        defaultMessage: 'Username:',
        description: 'Label for login username input',
        id: 'general.username'
    },
    password: {
        defaultMessage: 'Password:',
        description: 'Label for login password input',
        id: 'general.password'
    },
    signin: {
        defaultMessage: 'Sign in',
        description: 'Button text for user to sign in',
        id: 'general.signIn'
    },
    register: {
        defaultMessage: 'Register',
        description: 'Button text for user to register',
        id: 'general.register'
    },
    name: {
        defaultMessage: 'Name:',
        description: 'Label for name input',
        id: 'general.name'
    },
    email: {
        defaultMessage: 'Email:',
        description: 'Label for email input',
        id: 'general.email'
    },
    validationRequired: {
        defaultMessage: 'This field is required',
        description: 'Message to tell user they must enter text in a form field',
        id: 'form.validationRequired'
    },
    invalidCredentials: {
        defaultMessage: 'Invalid username: or password:',
        description: 'Message to tell user their credentials are invalid',
        id: 'login.invalidCredentials'
    }
});

const LoginDropdown = ({ className, isOpen, isRtl, onClose, intl }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [showPassword, setShowPassword] = useState(false); // New state for password visibility


    // Hard-coded credentials for testing purposes
    const validUsername = 'stem';
    const validPassword = '1234';

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        if (isLogin) {
            if (username === validUsername && password === validPassword) {
                setUsername('');
                setPassword('');
                setIsModalOpen(false);
                onClose();
            } else {
                setError(intl.formatMessage(LoginDropdownMessages.invalidCredentials));
            }
        } else {
            if (name && email && password) {
                alert('Registration successful');
                setIsLogin(true);
                setName('');
                setEmail('');
                setPassword('');
            } else {
                setError(intl.formatMessage(LoginDropdownMessages.validationRequired));
            }
        }
    };

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setError('');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        // onClose();
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    return (
        <MenuBarMenu
            className={className}
            open={isModalOpen}
            place={isRtl ? 'right' : 'left'}
            onRequestClose={handleCloseModal}
        >
            {/* Modal Overlay */}
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                {/* Modal Content */}
                <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 overflow-hidden">
                    <div className="flex flex-col lg:flex-row">
                        {/* Form Section */}
                        <div className="w-full lg:w-1/2 p-8 relative">
                            {/* Close Button */}
                            {/* <button
                                onClick={handleCloseModal}
                                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                                aria-label="Close"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button> */}

                            <h2 className="text-3xl font-bold text-center mb-2">Welcome to STEM World</h2>
                            <h3 className="text-xl text-gray-600 text-center mb-8">
                                {isLogin ? intl.formatMessage(LoginDropdownMessages.signin) : 'Register'}
                            </h3>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                {!isLogin && (
                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                            {intl.formatMessage(LoginDropdownMessages.name)}
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            id="name"
                                            type="text"
                                            placeholder={intl.formatMessage(LoginDropdownMessages.name)}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div>
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                        {intl.formatMessage(LoginDropdownMessages.username)}
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="username:"
                                        type="text"
                                        placeholder={intl.formatMessage(LoginDropdownMessages.username)}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                {!isLogin && (
                                    <div>
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                            {intl.formatMessage(LoginDropdownMessages.email)}
                                        </label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            id="email"
                                            type="email"
                                            placeholder={intl.formatMessage(LoginDropdownMessages.email)}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className="relative">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                        {intl.formatMessage(LoginDropdownMessages.password)}
                                    </label>
                                    <input
                                        className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="password"
                                        type={showPassword ? 'text' : 'password'} // Toggle between text and password
                                        placeholder={intl.formatMessage(LoginDropdownMessages.password)}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                                        aria-label="Toggle password visibility"
                                    >
                                        {showPassword ? (
                                            <svg className="w-5 h-5 mt-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825a6.963 6.963 0 003.196-.825M12 4.5C8.25 4.5 5.25 7.5 3 12c2.25 4.5 5.25 7.5 9 7.5s6-2.25 9-7.5c-2.25-4.5-5.25-7.5-9-7.5z" />
                                            </svg>
                                        ) : (
                                            <svg className="w-5 h-5 mt-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 6.825A6.963 6.963 0 0010.5 6.75c-3.75 0-6.75 3-9 7.5 2.25 4.5 5.25 7.5 9 7.5a6.963 6.963 0 003.375-.825M12 4.5c3.75 0 6.75 3 9 7.5-2.25 4.5-5.25 7.5-9 7.5s-6-2.25-9-7.5c2.25-4.5 5.25-7.5 9-7.5z" />
                                            </svg>
                                        )}
                                    </button>

                                </div>
                                {error && <p className="text-red-500 text-sm">{error}</p>}
                                <button
                                    type="submit"
                                    className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
                                >
                                    {isLogin ? intl.formatMessage(LoginDropdownMessages.signin) : intl.formatMessage(LoginDropdownMessages.register)}
                                </button>
                            </form>

                            <div className="mt-6 text-center">
                                <button
                                    onClick={toggleForm}
                                    className="text-blue-500 hover:text-blue-700 font-semibold transition duration-300"
                                >
                                    {isLogin ? 'Create an account' : 'Already have an account? Sign in'}
                                </button>
                            </div>
                        </div>

                        {/* CTA Section */}
                        <div className="hidden lg:flex w-1/2 items-center justify-center bg-gradient-to-r from-purple-500 to-blue-500 text-white p-8">
                            <div className="text-center">
                                <h2 className="text-4xl font-bold mb-4">
                                    {isLogin ? "Don't have an account?" : 'Already have an account?'}
                                </h2>
                                <p className="mb-8">{isLogin ? 'Start your journey in one click' : 'Sign in to access your account'}</p>
                                <button
                                    onClick={toggleForm}
                                    className="bg-white text-purple-600 hover:text-purple-800 font-bold py-3 px-6 rounded-full transition duration-300"
                                >
                                    {isLogin ? 'Create an Account' : 'Sign In'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MenuBarMenu>
    );
};

LoginDropdown.propTypes = {
    className: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired
};

export default injectIntl(LoginDropdown);
