import React, { useState, useRef } from 'react';
import Editor from '@monaco-editor/react';
import ReactConsole from 'react-console-emulator';
import styles from './code-editor.css';

const PYTHONTab = () => {
  const [code, setCode] = useState('');
  const consoleRef = useRef();

  const handleEditorChange = (value) => {
    setCode(value);
  };

  const handleRunCode = async () => {
    if (code.trim() === '') return;

    const response = await fetch('https://api.stemverse.app/python/runCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code }),
    });

    const result = await response.json();
    consoleRef.current.pushToStdout(result.output);
  };

  const handleClearCode = () => {
    setCode('');
    consoleRef.current.clearStdout();
  };

  const handlePythonCommand = async (input) => {
    const command = input.join(' ');
    const response = await fetch('https://api.stemverse.app/python/runCode', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code: command }),
    });

    const result = await response.json();
    return result.output;
  };

  const commands = {
    run: {
      description: 'Run the code in the editor',
      usage: 'run',
      fn: handleRunCode,
    },
    python: {
      description: 'Execute a Python command',
      usage: 'python <command>',
      fn: (...args) => handlePythonCommand(args),
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <button
          className={styles.runButton}
          onClick={handleRunCode}
          disabled={code.trim() === ''}
        >
          Run
        </button>
        <button
          className={styles.clearButton}
          onClick={handleClearCode}
        >
          Clear
        </button>
      </div>
      <div className={styles.editor}>
        <Editor
          height="400px"
          width="1000px"
          language="python"
          theme="vs-light"
          value={code}
          onChange={handleEditorChange}
        />
      </div>
      <div className={styles.console}>
        <ReactConsole
          commands={commands}
          noCommandFound="No such command"
          welcomeMessage=""
          promptLabel=">>>"
          readOnly={false}
          autoFocus
          ref={consoleRef}
        />
      </div>
    </div>
  );
};

export default PYTHONTab;
